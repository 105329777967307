/**
 * Bootstrap vars
 */

// Container max width customization
$container-max-widths: (
   sm: 100%,
   md: 100%,
   lg: 100%,
);

// Grid columns
$grid-columns: 12;
$grid-gutter-width: 32px;

// Change gutter width on breakpoint
$grid-gutter-default-widths: (
  //xs: 20px,
  //sm: 80px,// "sm" takes the same width as "xs" automatically
  // md: 20px,
  // lg: 32px
);

// If you need multiple gutter width go to _bootstrap-custom-grid-settings