/* You can add global styles to this file, and also import other style files */
@import "theme/bootstrap/bootstrap-custom-grid";

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-form-field {
  width: 100%;
}

.remember-forgot-password {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.content-wrap {
  padding: 20px;
  width: 100%;
}

.answer-group {
  display: flex;
  align-items: center;

  .answer_input {
    margin-right: 20px;
  }

  .answer_response {
    width: 20%;
    margin-right: 30px;
  }

  .answer_icon {
    cursor: pointer;
    color: red;
  }
}

.add-button {
  background: green;
  color: #fff;
  border-radius: 64% !important;
  padding: 0 !important;
  min-width: 40px !important;
  max-width: 40px;
}

.button-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.snack-error {
  background-color: #ff0000;
}

.question-list__add-button {
  position: absolute;
  top: 20px;
  right: 20px;
}

.mat-snack-bar-container {
  &.success {
    color: white;
    background-color: green;
  }

  &.error {
    color: white;
    background-color: red;
  }
}

.user-list__item.craft {
  height: 100%;
  width: 100%;

  p {
    margin-bottom: 0 !important;
  }
}

.address-validation-row {
  max-width: 155px;
  margin: 10px 0 20px 0;
  p {
    color: #0000008a;
    font-size: 14px;
    font-weight: bold;
}
}