/**
 * Bootstrap grid customization
 */

// Custom grid vars
@import 'bootstrap-custom-grid-vars';
// Newest grid version
@import '~bootstrap/scss/bootstrap-grid';
// Customize grid 
@import 'bootstrap-custom-grid-settings';
// Define Breakpoints
@import 'bootstrap-custom-grid-mobile-first';